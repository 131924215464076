import request from '@/utils/request'

// 短信相关

export function sendVerifyCode(phone) {
    return request({
      url: '/api/data/sendVerifyCode',
      method: 'post',
      data: { phone }
    }) 
}

export function checkVerifyCode(phone, code) {
    return request({
      url: '/api/data/checkVerifyCode',
      method: 'post',
      data: { phone, code }
    }) 
}
