<template>
    <v-container fluid class="container">
        <v-row no-gutters>
            <v-col cols="12" md="7" class="main-part">
                <div>
                    <v-img class="title-logo" src="@/assets/images/common/logo_white_no_text.png" contain></v-img>
                </div>
                <div class="title-row">
                    <div class="title-border">酷客编程 · </div>
                    <div class="slogan">最好用的编程教育云平台</div>
                </div>
                <div class="bottom-slogan">科技改变世界 · 科技改变中国</div>
            </v-col>
            <v-col cols="6" md="5" class="login-part d-flex align-center justify-center" >
                <v-row no-gutters class="align-start">
                    <v-col
                        class="login-part
                                d-flex
                                align-center
                                justify-center
                                flex-column">
                        <div class="login-wrapper">
                            <!-- 切换微信/密码登录 -->
                            <div class="login-tab" @click.stop="switchLoginType">
                                <img src="@/assets/images/admin/pw-login.png" style="height: 72px;" v-if="isWechatLogin">
                                <img src="@/assets/images/admin/qrcode-login.png" style="height: 72px;" v-else>
                            </div>
                            <div class="login-tab-tips">
                                <span>{{ isWechatLogin ? "密码登录" : "微信登录" }}</span>
                            </div>

                            <!-- 一：输入手机号 -->
                            <div class="tab-container" v-if="step == 1">
                                <div class="login-slogan display-2 font-weight-medium" >
                                    老师，早上好！
                                </div>
                                <div class="login-slogan-2 font-weight-medium">
                                    欢迎使用酷客云编程
                                </div>
                                <div class="input-container">
                                    <div class="form-row">
                                        <v-select
                                            style="width: 50px; margin-right: 20px;"
                                            :items="areaCode"
                                            label="+86"
                                            dense
                                            outlined
                                            disabled
                                        ></v-select>
                                        <v-text-field
                                            v-model="phone"
                                            label="手机号"
                                            outlined
                                            dense
                                            required
                                            color="#536DFE"
                                            @keydown.enter="checkUser()"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <div
                                        class="d-flex justify-space-between"
                                        style="margin-top: 10px"
                                    >
                                        <v-btn
                                            class="text-capitalize btn"
                                            block
                                            dark
                                            large
                                            color="#536DFE"
                                            @click="checkUser()"
                                        >
                                            登陆 / 注册
                                        </v-btn>
                                    </div>
                                    <div class="tips">
                                        <v-icon left color="#ccc" size="16"> mdi-check-circle </v-icon>
                                        <div>未注册的手机号将自动生成新账号</div>
                                    </div>
                                    <!-- <div class="wechat">
                                        <img src="@/assets/images/admin/wechat.png" style="height: 32px;">
                                        <div class="wechat-text">微信登录</div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- 二：登录，密码登录 -->
                            <div class="tab-container" v-if="step == 2">
                                <div class="form-row">
                                    <div class="return" @click.stop="goStep(1)">
                                        <v-icon color="#888" size="30"> mdi-chevron-left </v-icon>
                                        <div>返回</div>
                                    </div>
                                </div>
                                <div class="login-slogan-2 font-weight-medium" style="margin-top: 15px;">
                                    Hi, 欢迎回来
                                </div>
                                <div class="input-container">
                                    <div class="form-label">请输入登录密码</div>
                                    <div class="form-row">
                                        <v-text-field
                                            v-model="password"
                                            type="password"
                                            label="登录密码"
                                            outlined
                                            dense
                                            required
                                            autofocus
                                            hide-details
                                            color="#536DFE"
                                            @keydown.enter="login"
                                        ></v-text-field>
                                    </div>
                                    <div class="sms-login-text">
                                        <div @click.stop="goStep(3)" style="cursor: pointer;">验证码登录</div>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <div
                                        class="d-flex justify-space-between"
                                        style="margin-top: 10px"
                                    >
                                        <v-btn
                                            class="text-capitalize btn"
                                            block
                                            dark
                                            large
                                            color="#536DFE"
                                            @click="login"
                                        >
                                            立即开始
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <!-- 三，登录，短息验证码登录 -->
                            <div class="tab-container" v-if="step == 3">
                                <div class="form-row">
                                    <div class="return" @click.stop="goStep(1)">
                                        <v-icon color="#888" size="30"> mdi-chevron-left </v-icon>
                                        <div>返回</div>
                                    </div>
                                </div>
                                <div class="login-slogan-2 font-weight-medium" style="margin-top: 15px;">
                                    Hi, 欢迎回来
                                </div>
                                <div class="input-container">
                                    <div class="form-label">请输入验证码</div>
                                    <div class="form-row">
                                        <v-text-field
                                            v-model="code"
                                            type="password"
                                            label="验证码"
                                            required
                                            outlined
                                            dense
                                            hide-details
                                            color="#536DFE"
                                            @keydown.enter="smsLogin"
                                        >
                                            <template v-slot:append>
                                                <div class="btn-get-sms-counting" v-if="isCodeLoading">
                                                    {{timeCount}} 秒
                                                </div>
                                                <div class="btn-get-sms" @click.stop="getCode" v-else>
                                                    获取验证码
                                                </div>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div class="sms-login-text">
                                        <div @click.stop="goStep(2)" style="cursor: pointer;">密码登录</div>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <div
                                        class="d-flex justify-space-between"
                                        style="margin-top: 10px"
                                    >
                                        <v-btn
                                            class="text-capitalize btn"
                                            block
                                            dark
                                            large
                                            color="#536DFE"
                                            @click="smsLogin"
                                        >
                                            立即开始
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <!-- 四：注册 -->
                            <div class="tab-container" v-if="step == 4">
                                <div class="form-row">
                                    <div class="return" @click.stop="goStep(1)">
                                        <v-icon color="#888" size="30"> mdi-chevron-left </v-icon>
                                        <div>返回</div>
                                    </div>
                                </div>
                                <div class="input-container" style="height: 270px; margin-top: 20px;">
                                    <div class="form-label font-weight-medium" style="margin-bottom: 5px; color: #555;">请填写您的名字</div>
                                    <div class="form-row" style="margin-top: 10px;">
                                        <v-text-field
                                            v-model="name"
                                            label="我的名字"
                                            outlined
                                            dense
                                            required
                                            autofocus
                                            hide-details
                                            color="#536DFE"
                                        ></v-text-field>
                                    </div>
                                    <div class="form-row" style="margin-top: 50px;">
                                        <v-text-field
                                            v-model="code"
                                            type="password"
                                            label="验证码"
                                            required
                                            outlined
                                            dense
                                            hide-details
                                            color="#536DFE"
                                        >
                                            <template v-slot:append>
                                                <div class="btn-get-sms-counting" v-if="isCodeLoading">
                                                    {{timeCount}} 秒
                                                </div>
                                                <div class="btn-get-sms" @click.stop="getCode" v-else>
                                                    获取验证码
                                                </div>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div class="form-row" style="margin-top: 20px;">
                                        <v-text-field
                                            v-model="password"
                                            type="password"
                                            label="设置密码"
                                            outlined
                                            dense
                                            required
                                            hide-details
                                            color="#536DFE"
                                            @keydown.enter="smsRegister"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <div
                                        class="d-flex justify-space-between"
                                        style="margin-top: 10px"
                                    >
                                        <v-btn
                                            class="text-capitalize btn"
                                            block
                                            dark
                                            large
                                            color="#536DFE"
                                            @click="smsRegister"
                                        >
                                            立即开始
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <!-- 五：微信登录 -->
                            <div class="tab-container" v-show="step == 5">
                                <div class="login-slogan-2 font-weight-medium" style="justify-content: center; margin-top: 70px;">
                                    微信扫码登录
                                </div>
                                <div class="qrcode-container">
                                    <div class="qrcode">
                                        <img :src="qrcodeUrl">
                                        <!-- <div id="qrCodeDiv" ref="qrCodeDiv" style="margin: 0 auto;"></div> -->

                                        <div class="qrcode-border">
                                            <img src="@/assets/images/admin/qrcode-border.png">
                                        </div>
                                    </div>
                                    <div class="desc">最好用的编程教育云平台</div>
                                </div>
                            </div>
                            <!-- 六：登录补充：之前没有填写昵称的，要求补充昵称 -->
                            <div class="tab-container" v-if="step == 6">
                                <div class="form-row">
                                    <div class="return" @click.stop="goStep(1)">
                                        <v-icon color="#888" size="30"> mdi-chevron-left </v-icon>
                                        <div>返回</div>
                                    </div>
                                </div>
                                <div class="input-container" style="height: 270px; margin-top: 20px;">
                                    <div class="form-label font-weight-medium" style="margin-bottom: 5px; color: #555;">请填写您的名字</div>
                                    <div class="form-row" style="margin-top: 10px;">
                                        <v-text-field
                                            v-model="name"
                                            label="我的名字"
                                            outlined
                                            dense
                                            required
                                            autofocus
                                            hide-details
                                            color="#536DFE"
                                            @keydown.enter="lastLogin"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <div
                                        class="d-flex justify-space-between"
                                        style="margin-top: 10px"
                                    >
                                        <v-btn
                                            class="text-capitalize btn"
                                            block
                                            dark
                                            large
                                            color="#536DFE"
                                            @click="lastLogin"
                                        >
                                            完善信息，立即开始
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div class="bottom-text" @click.stop="goBeian()">
                    ©2022 畅言科技（北京）有限公司 | 京ICP备16045198号-4
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QRCode from 'qrcodejs2';
import { isPhone } from "@/utils/util";
import { adminGetLoginQrcode, adminCheckUser, adminLastLogin } from "@/api/user";
import { sendVerifyCode, checkVerifyCode } from "@/api/sms";

const TIME_COUNT = 60;

export default {
    name: "Login",
    data() {
        return {
            // 当前是微信登录or密码登录
            isWechatLogin: false,
            // 正在获取验证码loading
            isCodeLoading: false, 

            qrcodeUrl: "",

            timer: null, // 计时器
            timeCount: TIME_COUNT, // 倒计时

            name: "",
            phone: "",
            code: "",
            password: "",
            showPassword: false,

            areaCode: ['+86'],
            step: 1,

            intervalCheck: null,

            // 检查规则
            phoneRules: [
                (v) => !!v || "必须填写手机号",
            ],
            userRules: [
                (v) => !!v || "必须填写用户名/手机号",
            ],
            passRules: [
                (v) => !!v || "必须填写密码",
                (v) => v.length >= 6 || "密码长度必须大于6位",
            ],
        };
    },
    mounted() {
        this.getQrcode();
        // this.checker();
    },
    methods: {
        checker() {
            if (!this.intervalCheck) {
                this.intervalCheck = setInterval(this.qrcodeLogin, 3000);
            }
        },
        clearChecker() {
            if (this.intervalCheck) {
                clearInterval(this.intervalCheck);
                this.intervalCheck = null;
            }
        },
        qrcodeLogin() {
            let that = this;
            if (this.isWechatLogin) {
                this.$store.dispatch("user/adminQrcodeLogin").then(response => {
                    // console.log(response);
                    if (response.msg == 'SUCCESS') {
                        this.clearChecker();
                        this.$router.push("/dashboard");
                    }
                }).catch(function (err) {
                    console.log(err);
                });
            }
        },
        goStep(step) {
            if (step == 2 || step == 3 || step == 4) {
                this.password = ""
                this.code = ""
            }
            this.step = step;
        },
        async checkUser() {
            if (!isPhone(this.phone)) {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写正确的手机号'});
                return;
            }

            let res = await adminCheckUser(this.phone);
            if (res.msg == "NOT_FOUND") {
                // 用户（手机号）不存在，跳转到注册
                this.goStep(4);

            } else if (res.msg == "SUCCESS") {
                // 用户（手机号）存在，跳转到注册
                this.goStep(2);

            } else {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写正确的手机号'});
            }
        },
        getQrcode() {
            adminGetLoginQrcode().then(response => {
                // console.log(response.data)
                this.qrcodeUrl = response.data;

                // let that = this
                // this.$nextTick(function () {
                //     that.makeCode()
                // })

            }).catch(function (err) {
                console.log(err);
            });
        },
        makeCode() {
            this.$refs.qrCodeDiv.innerHTML = ''

            new QRCode(this.$refs.qrCodeDiv, {
                text: this.qrcodeUrl,
                width: 150,
                height: 150,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.L
            })
        },
        login() {
            if (this.phone == '' || this.password == '') {
                // 如果为空，不提示，避免快速多次按回车导致的误提示
                // this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写正确的用户名/密码'});
                // console.log('None')
                let pass;

            } else {
                this.$store.dispatch("user/login", {
                    username: this.phone,
                    password: this.password
                }).then((response) => {

                    if (response.msg == 'SUCCESS') {
                        if (response.data.name && response.data.name != "") {
                            this.$router.push("/dashboard")
                        } else {
                            this.goStep(6)
                        }

                    } else {
                        // console.log('login failed')
                        this.$store.dispatch('web/SetAlert', {type: 'error', msg: '手机号/验证码有误'});
                    }
                    
                }).catch(function (err) {
                    console.log(err);
                });
            }
        },
        smsLogin() {
            if (this.phone == '' || this.code == '') {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写正确的手机号/验证码'});
                // console.log('None')

            } else {
                this.$store.dispatch("user/smsLogin", {
                    phone: this.phone,
                    code: this.code
                }).then((response) => {

                    if (response.msg == 'SUCCESS') {
                        if (response.data.name && response.data.name != "") {
                            this.$router.push("/dashboard")
                        } else {
                            this.goStep(6)
                        }

                    } else {
                        this.$store.dispatch('web/SetAlert', {type: 'error', msg: '手机号/验证码有误'});
                    }
                    
                }).catch(function (err) {
                    console.log(err);
                });
            }
        },
        // 补充姓名后，再次登录
        async lastLogin() {
            if (this.name == '') {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写姓名'});
                return
            }
            let res = await adminLastLogin(this.name)
            if (res.msg == 'SUCCESS') {
                this.$router.push("/dashboard");
            } else {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '登录信息有误，请重新登录'});
                this.goStep(1);
            }
        },
        switchLoginType() {
            this.isWechatLogin = !this.isWechatLogin;

            if (this.isWechatLogin) {
                this.step = 5;

                this.checker();
                this.getQrcode();

            } else {
                this.step = 1;

                this.clearChecker();
            }
        },
        getCode() {
            if (this.isCodeLoading) {
                return
            }

            if (this.phone == "") {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '请填写正确的手机号'});
                return
            }

            this.isCodeLoading = true;

            // 发送短信验证码
            sendVerifyCode(this.phone);

            this.timer = setInterval(() => {
                if (this.timeCount > 1 && this.timeCount <= TIME_COUNT) {
                    --this.timeCount;
                } else {
                    this.isCodeLoading = false;

                    this.timeCount = TIME_COUNT;
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        smsRegister() {
            if (this.phone == '' || this.name == '' || this.code == '' || this.password == '') {
                this.$store.dispatch('web/SetAlert', {type: 'error', msg: '昵称/验证码/密码不能为空'});

            } else {
                this.$store.dispatch("user/smsRegister", {
                    phone: this.phone,
                    code: this.code,
                    password: this.password,
                    name: this.name,

                }).then((response) => {

                    if (response.msg == 'SUCCESS') {
                        this.$router.push("/dashboard");
                    } else {
                        this.$store.dispatch('web/SetAlert', {type: 'error', msg: '手机号/验证码有误'});
                    }
                    
                }).catch(function (err) {
                    console.log(err);
                });
            }
        },
        goBeian() {
            window.location.href = 'http://beian.miit.gov.cn';
        },
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
body {
    background-color: #f6f7ff !important;
}
.blue-color {
    color: #536DFE;
}
.title-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-border {
    font-size: 36px !important;
    font-weight: bold;
    color: #fff;
    letter-spacing: .5px;
}
.slogan {
    font-size: 30px !important;
    font-weight: bold;
    margin-left: 12px;
    color: #fff;
    letter-spacing: .5px;
}
.container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 0;

    .btn {
        font-size: 15px;
        font-weight: bold;
    }
    .btn-register {
        position: absolute;
        top: 20px;
        right: 30px;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        background-color: #536dfe;
        border-radius: 50px;
        padding: 8px 20px;
        cursor: pointer;
    }
    .btn-login {
        position: absolute;
        top: 20px;
        right: 30px;
        color: #536dfe;
        font-size: 15px;
        font-weight: bold;
        background-color: #fff;
        border: 1px solid #536dfe;
        border-radius: 50px;
        padding: 8px 20px;
        cursor: pointer;
    }
    .btn-get-sms {
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        padding: 8px 15px;
        cursor: pointer;
        // color: #536dfe;
        // color: #5068eb;
        color: #687df3;
        // border: 1px solid #536dfe;
        border-radius: 50px;
        margin-bottom: 8px;
        &:hover {
            color: #fff;
            background-color: #536dfe;
        }
    }
    .btn-get-sms-counting {
        width: 100px;
        font-size: 14px;
        // font-weight: bold;
        padding: 8px 15px;
        color: #b2b2b2;
        border: 1px solid #b2b2b2;
        border-radius: 50px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-logo {
        height: 50px;
        margin-bottom: 32px;
    }

    .main-part {
        width: 100%;
        height: 100vh;
        background-color: #536dfe;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // div {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        //     p {
        //         text-align: center;
        //         font-family: "Roboto", sans-serif;
        //         font-size: 84px;
        //         color: white;
        //         font-weight: 500;
        //     }
        //     .v-image {
        //         margin-bottom: 32px;
        //     }
        // }
    }
    .login-part {
        width: 100%;
        height: 100vh;
        background-color: #f6f7ff;
        overflow-y: auto;
        .row {
            // background-color: #f6f7ff;
            background-color: #fff;
        }
        .login-wrapper {
            position: relative;
            width: 420px;
            height: 500px;
            background-color: #fff;
            // background-color: #f6f7ff;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .login-slogan {
                display: flex;
                justify-content: flex-start;
                font-size: 20px;
                margin-bottom: 15px;
                color: #555;
            }
            .login-slogan-2 {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 20px;
                font-size: 20px;
                letter-spacing: 2px;
                color: #555;
            }
            .v-btn.google {
                box-shadow: 0 3px 11px 0 #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A !important;
            }
            .v-tabs {
                .v-tab {
                    font-size: 18px;
                    // background-color: #f6f7ff;
                    background-color: #fff;
                }
                .v-item-group {
                    // background-color: #f6f7ff;
                    background-color: #fff;
                }
            }

            .login-tab {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
            .login-tab-tips {
                position: absolute;
                right: 60px;
                top: 22px;
                width: 78px;
                height: 28px;
                background: url('~@/assets/images/admin/tips-border.png') no-repeat 0 0;
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                line-height: 28px;
                font-size: 14px;
                font-weight: 400;
                font-family: "PingFang SC";
                color: #1472FF;
            }
        }
        .v-footer {
            position: absolute;
            bottom: 0;
            // background-color: #f6f7ff;
            background-color: #fff;
        }
    }
    .bottom-slogan {
        position: absolute;
        bottom: 60px;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
    }
    .bottom-text {
        position: absolute;
        bottom: 60px;
        display: flex;
        overflow-wrap: nowrap;
        text-align: left;
        font-size: 14px;
        color: #888;
        letter-spacing: 1px;
    }
}
.tab-container {
    // background-color: #f6f7ff !important;
    background-color: #fff;
    width: 100%;
    height: 400px;
}
.input-container {
    margin-top: 40px;
    height: 180px;
}
.btn-container {
    width: 100%;
}
.qrcode-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .qrcode {
        position: relative;
        margin-bottom: 15px;
        img {
            height: 160px;
            width: 160px;
            // border: 1px solid #999;
        }

        .qrcode-border {
            position: absolute;
            top: -10px;
            left: -10px;
            height: 180px;
            width: 180px;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
    
    .desc {
        font-size: 16px;
        color: #888;
        letter-spacing: 1px;
        margin-top: 30px;
    }
}
.tips {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #aaa;
    letter-spacing: .5px;
    font-size: 13px;
    margin-top: 15px;
}
.wechat {
    margin-top: 15px;
    .wechat-text {
        margin-top: 4px;
        font-size: 12px;
        color: #aaa;
    }
}
.return {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-left: -6px;
}
.form-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.form-label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    color: #888;
    margin-bottom: 12px;
}
.sms-login-text {
    font-size: 13px;
    font-weight: bold;
    // color: #5068eb;
    color: #687df3;
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
}
</style>
